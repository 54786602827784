<template>
  <div class="OnlineBookkeeping">
    <h3>在线记账</h3>
    <!-- 支出和收入的弹窗 -->
    <UpProof v-if="IsIncome" @closeDialog="closeDialog" ref="IsIncome" />
    <!-- 查看财报记录 -->
    <LookProof v-if="lookProof" @closeDialog2="closeDialog2" ref="LookProof" />
    <!-- 顶部按钮 -->
    <div class="proof">
      <div class="UpDisburseProof" @click="UpDisburseProof()">上传支出凭证</div>
      <div class="UpIncomeProof" @click="UpIncomeProof()">上传收入凭证</div>
      <div class="LookProof" @click="LookProof()">查看财报记录</div>
    </div>
    <!-- 根据条件搜索 -->
    <div class="searchItem">
      <el-form :model="form" label-width="80px" inline ref="form">
        <el-form-item label="编号：">
          <el-input
            style="width: 190px"
            v-model="form.incomePaymentId"
            placeholder="单号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="收支类别:">
          <el-select v-model="form.fileType" style="width: 200px">
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="上传时间：">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd"
            @change="changeTime"
          >
          </el-date-picker>
        </el-form-item>

        <div>
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- table列表 -->
    <div class="TableList">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      >
        <template slot="fileType" slot-scope="scope">
          <span>{{ scope.row.fileType == 1 ? "收入" : "支出" }}</span>
        </template>
        <template slot="files" slot-scope="scope">
          <!-- <el-image
            :src="
              baseUrl +
              'admin/file/get?ossFilePath=' +
              JSON.parse(scope.row.files).imgUrl
            "
            @click="BigImg(JSON.parse(scope.row.files).imgUrl)"
            :preview-src-list="srcList"
            :alt="JSON.parse(scope.row.files).FilesName"
          /> -->
          <el-image
            :src="
              baseUrl +
              'admin/file/get?ossFilePath=' +
              JSON.parse(scope.row.files)[0].imgUrl
            "
            :preview-src-list="srcList"
            @click="BigImg(JSON.parse(scope.row.files)[0].imgUrl)"
          >
            <div slot="error" class="image-slot">
              <span>{{ JSON.parse(scope.row.files)[0].FilesName }}</span>
            </div>
          </el-image>
        </template>
        <template slot="createTime" slot-scope="scope">
          <span>{{
            scope.row.createTime ? scope.row.createTime.substr(0, 10) : ""
          }}</span>
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
import TablePage from "@/components/table/table-page";
import { AccountStatement } from "../tableColumn";

//上传凭证
import UpProof from "./component/UpProof";
//查看财报记录
import LookProof from "./component/LookProof";
import { credentialsSelectList } from "@/api/ChargeUp";

export default {
  data() {
    return {
      form: {
        incomePaymentId: "", //编号
        fileType: "", //收支类别
        time: ["", ""], //上传时间
      },
      types: [
        {
          label: "支出",
          value: "2",
        },
        {
          label: "收入",
          value: "1",
        },
      ], //收支类别
      columns: AccountStatement, //table表头
      tableData: [], //table数据
      baseUrl: window.globalUrl.HOME_API + "admin/file/get?ossFilePath=",
      query: {
        pnum: 1,
        psize: 10,
      }, //分页
      total: 0, //数量
      loading: false, //加载状态
      IsIncome: false, //收入或者支出的弹窗
      lookProof: false, //查看财报记录
      startTime: "", //查询开始时间
      endTime: "", //查询结束时间
      srcList: [], //预览图片列表
    };
  },

  components: {
    TablePage,
    UpProof,
    LookProof,
  },
  mounted() {
    //收入支出列表
    this.credentialsSelectList();
  },

  methods: {
    //筛选时间
    changeTime(val) {
      this.startTime = val[0];
      this.endTime = val[1];
      console.log(this.startTime, this.endTime);
    },
    // 点击搜索按钮
    async search() {
      let params = {
        incomePaymentId: this.form.incomePaymentId,
        fileType: this.form.fileType,
        createStartTime: this.startTime,
        createEndTime: this.endTime,
        current: this.query.pnum,
        size: this.query.psize,
      };
      let res = await credentialsSelectList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },
    //收入或者支出弹窗关闭
    closeDialog(IsIncome) {
      this.IsIncome = IsIncome;
      this.credentialsSelectList();
    },
    //收入支出列表
    async credentialsSelectList() {
      let params = {
        current: this.query.pnum,
        size: this.query.psize,
      };
      let res = await credentialsSelectList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },
    //打开支出弹窗
    UpDisburseProof() {
      this.IsIncome = true;
      let creator_type = 2;
      this.$nextTick(() => {
        this.$refs.IsIncome.disburse(creator_type);
      });
    },
    //打开收入弹窗
    UpIncomeProof() {
      this.IsIncome = true;
      let creator_type = 1;
      this.$nextTick(() => {
        this.$refs.IsIncome.income(creator_type);
      });
    },
    //查看财报记录
    LookProof() {
      this.lookProof = true;
    },
    //关闭查看财报记录
    closeDialog2() {
      this.lookProof = false;
    },
    //图片预览
    BigImg(Url) {
      let ImgUrl = this.baseUrl + "admin/file/get?ossFilePath=" + Url;
      this.srcList.push(ImgUrl);
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.credentialsSelectList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.credentialsSelectList();
    },
  },
};
</script>

<style lang="less" scoped>
.OnlineBookkeeping {
  /*顶部按钮*/
  .proof {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    div {
      width: 25%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-radius: 10px;
      color: white;
    }
    .UpDisburseProof {
      background: #ed8b97;
    }
    .UpIncomeProof {
      background: #169bd5;
    }
    .LookProof {
      background: #d7d7d7;
    }
  }
  /*根据条件搜索*/
  .searchItem {
    .el-form {
      width: 100%;
      display: flex;
    }

    /deep/.el-form-item {
      width: 25% !important;
      .el-input {
        width: 200px;
      }
      .el-form-item__label {
        width: 100px !important;
      }
      .el-form-item__content {
        width: 200px;
      }
      .el-date-editor {
        width: 200px;
      }
    }
  }
}
/deep/tbody {
  tr {
    td {
      img {
        width: 100px;
        height: 50px;
      }
    }
  }
}
</style>
